import type { FunctionComponent } from 'react';
import React from 'react';
import TagManager from 'react-gtm-module';

import { useQuotationEngineContext } from 'src/components/layouts/PublicLayout/components/QuotationEngine/QuotationEngineContext';
import { TIRE_SERVICE } from 'src/components/layouts/PublicLayout/components/QuotationEngine/utils/constants';
import { Icon, InlineCritizr } from 'src/components/shared';
import type { InlineCritizrProps } from 'src/components/shared/InlineCritizr/InlineCritizr';
import { Col } from 'src/components/ui';

import style from './SearchEngineMobile.module.scss';

const SearchEngineMobile: FunctionComponent<InlineCritizrProps> = ({
  averageRating,
  totalRatings,
}) => {
  const { openQuotationEngine } = useQuotationEngineContext();

  return (
    <Col
      className={style.search}
      onClick={() => {
        openQuotationEngine({});
      }}
    >
      <InlineCritizr
        averageRating={averageRating}
        totalRatings={totalRatings}
        className={style.searchCritizr}
        starsPosition={'right'}
        txtRatingColor={'Brown'}
      />
      <Col>
        <button
          type="button"
          onClick={() => openQuotationEngine({})}
          className={style.searchBouton}
          data-testid="home_engine_mobile_button"
        >
          <Icon
            name="recherche"
            className={style.btnLeftIcon}
            width={36}
            height={36}
            color={'white'}
          />
          <div className={style.searchBoutonText}>
            Devis et rendez-vous immédiat
          </div>
          <Icon
            name="fleche-d"
            className={style.btnRightIcon}
            width={25}
            height={25}
            color={'white'}
          />
        </button>

        <button
          onClick={() => {
            openQuotationEngine(TIRE_SERVICE);
            TagManager.dataLayer({
              dataLayer: {
                event: 'eventGA',
                eventCategory: 'changement pneus',
                eventAction: 'clic sur changer mes pneus',
                eventLabel: '/',
              },
            });
          }}
          type={'button'}
          className={style.btnMobile}
        >
          <Icon
            name={'tire'}
            width={36}
            height={46}
            className={style.btnIconLeft}
          />
          <span className={style.btnText}>CHANGER MES PNEUS</span>
          <Icon name={'fleche-d'} width={28} height={28} />
        </button>
      </Col>
    </Col>
  );
};

export default SearchEngineMobile;
