import TagManager from 'react-gtm-module';

export const EVENT_CATEGORY = {
  guide: 'guide conseil',
  category: 'categorie guide conseil',
  guidePage: 'page conseil',
};

export const gtmGuideClickEvent =
  (
    eventLabel: string,
    eventCategory: string,
    eventAction: string,
    pageType?: string,
    cb?: () => void,
  ) =>
  () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'eventGA',
        eventCategory,
        eventAction,
        eventLabel,
        ...(pageType && { pageType }),
      },
    });

    if (cb) {
      cb();
    }
  };
