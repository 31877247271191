import { useRouter } from 'next/router';
import type { FunctionComponent } from 'react';
import React from 'react';
import type { Advice, AdviceCategory } from 'src/models';
import {
  EVENT_CATEGORY,
  gtmGuideClickEvent,
} from 'src/pages/guides/guide-conseil/trackingUtils';

import { Link, Title4 } from 'src/components/shared';
import Responsive from 'src/components/shared/Responsive/Responsive';

import styles from './CMSRelatedLinks.module.scss';

type CMSRelatedLinksProps = {
  title: string;
  allLinksTitle: string;
  allLinksUrl: string;
  guidesLinks: AdviceCategory[] | Advice[];
  guideTitle?: string;
};

const CMSRelatedLinks: FunctionComponent<CMSRelatedLinksProps> = ({
  guidesLinks,
  title,
  allLinksTitle,
  allLinksUrl,
  guideTitle,
}) => {
  const { pathname } = useRouter();

  const getEventData = () => {
    if (pathname === '/guides/guide-conseil/[familyId]') {
      return {
        eventAllLinksAction: 'clic voir tous les themes',
        eventCategory: EVENT_CATEGORY.category,
        eventAction: 'clic thematique',
        eventLabel: '/',
      };
    }

    if (pathname === '/guides/guide-conseil/[familyId]/[tipId]') {
      return {
        eventAllLinksAction: 'clic voir tous les conseils',
        eventCategory: EVENT_CATEGORY.guidePage,
        eventAction: 'clic conseil',
        eventLabel: guideTitle ?? '',
      };
    }

    if (pathname === '/') {
      return {
        eventAllLinksAction: 'clic autres thèmes',
        eventCategory: 'homepage',
        eventAction: 'clic autres thèmes',
        eventLabel: 'voir tous les thèmes',
      };
    }

    return {
      eventAllLinksAction: '',
      eventCategory: '',
      eventAction: '',
      eventLabel: '',
    };
  };

  const eventData = getEventData();

  const renderRelatedGuideLink = (
    related: Advice | AdviceCategory,
    index: number,
  ) => (
    <li key={related.url}>
      <Title4 className={styles.guidesLinkTitle}>
        <p>
          <Link
            className={styles.guidesLink}
            data-testid={`tip_related_link_${index}`}
            href={related.url}
            onClick={gtmGuideClickEvent(
              related.title,
              eventData.eventCategory,
              eventData.eventAction,
            )}
          >
            <strong>{related.title}</strong>
          </Link>
        </p>
        <span>{'>'}</span>
      </Title4>
    </li>
  );

  return (
    <div className={styles.linksSection}>
      <div className={styles.title}>{title}</div>
      <Responsive is="desktop" cloneChild>
        <ul className={styles.linksWrapper}>
          {guidesLinks.slice(0, 4)?.map(renderRelatedGuideLink)}
        </ul>
      </Responsive>
      <Responsive is="mobile" cloneChild>
        <ul className={styles.linksWrapper}>
          {pathname === '/'
            ? guidesLinks.slice(0, 2)?.map(renderRelatedGuideLink)
            : guidesLinks.slice(0, 4)?.map(renderRelatedGuideLink)}
        </ul>
      </Responsive>
      <Link
        onClick={gtmGuideClickEvent(
          eventData.eventLabel,
          eventData.eventCategory,
          eventData.eventAllLinksAction,
        )}
        className={styles.allLinks}
        href={allLinksUrl}
      >
        {allLinksTitle}
      </Link>
    </div>
  );
};

export default CMSRelatedLinks;
