import React from 'react';
import type { FunctionComponent } from 'react';

import { promiseEntries } from '../constants';
import style from './PromiseText.module.scss';

import { mergeClassNames } from 'src/utils/ReactUtils';

const PromiseText: FunctionComponent = () => {
  return (
    <div className={style.promise}>
      <div
        className={mergeClassNames([style.promiseText, style.promiseTextRed])}
      >
        {promiseEntries.text1}
      </div>
      <div
        className={mergeClassNames([style.promiseText, style.promiseTextBrown])}
      >
        {promiseEntries.text2}
      </div>
    </div>
  );
};

export default PromiseText;
