import React from 'react';
import type { FunctionComponent } from 'react';

import Promise from 'src/components/shared/Promise/Promise';
import Responsive from 'src/components/shared/Responsive/Responsive';

import { promiseEntries, stepsEntries } from '../constants';
import style from './PromiseAndSearch.module.scss';
import SearchEngine from './SearchEngine/SearchEngine';

import type { ServiceFamilyModel } from 'src/models/service/serviceFamily.model';

type PromiseAndSearchProps = {
  servicesFamily: ServiceFamilyModel[];
  averageRating: number;
  totalRatings: number;
};
const PromiseAndSearch: FunctionComponent<PromiseAndSearchProps> = ({
  servicesFamily,
  averageRating,
  totalRatings,
}) => (
  <div className={style.container}>
    <Responsive is={'desktop'} cloneChild>
      <div className={style.mainPromise}>
        <Promise
          imageUrl={promiseEntries.imageUrl}
          title={promiseEntries.title}
          text1={promiseEntries.text1}
          text2={promiseEntries.text2}
          detail={promiseEntries.detail}
        />
      </div>
    </Responsive>

    <div className={`d-none d-sm-block ${style.mainSearch}`}>
      <SearchEngine
        servicesFamily={servicesFamily}
        steps={stepsEntries}
        averageRating={averageRating}
        totalRatings={totalRatings}
        countServices={56}
        title="Devis et rendez-vous facile et immédiat dans les garages ad"
      />
    </div>
  </div>
);

export default PromiseAndSearch;
