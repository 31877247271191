import type { FunctionComponent, MouseEvent } from 'react';
import React from 'react';
import TagManager from 'react-gtm-module';

import { Icon, Link } from '../index';
import styles from './Contact.module.scss';

import { getDataTestId, usePageType } from 'src/utils/ReactUtils';

export type ContactProps = {
  linkPath: string;
  label: string;
  onClick?: () => void;
  dataTestId?: string;
};

const Contact: FunctionComponent<ContactProps> = ({
  linkPath,
  label,
  onClick,
  dataTestId,
}) => {
  const pageType = usePageType();

  const gtmClickEvent = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'eventGA',
        eventCategory: 'contact',
        eventAction: 'nous contacter',
        eventLabel: 'clic cta',
        pageType,
      },
    });
  };

  const handleOnClick = (event: MouseEvent<HTMLAnchorElement>) => {
    gtmClickEvent();
    if (onClick) {
      onClick();
      event.preventDefault();
      return;
    }
  };

  return (
    <Link onClick={handleOnClick} href={linkPath} className={styles.contact}>
      <Icon color={'white'} name={'questions'} height={37} width={37} />
      <span className={styles.contactLabel} {...getDataTestId({ dataTestId })}>
        {label}
      </span>
    </Link>
  );
};

export default Contact;
