import { useRouter } from 'next/router';
import React from 'react';
import type { FunctionComponent } from 'react';

import { useQuotationEngineContext } from 'src/components/layouts/PublicLayout/components/QuotationEngine/QuotationEngineContext';
import { Icon } from 'src/components/shared';
import type { IconType } from 'src/components/shared/Icon/types';

import { gtmHandleServiceClick } from '../trackingUtils';
import style from './Services.module.scss';

import type { ServiceFamilyModel } from 'src/models/service/serviceFamily.model';

import { mergeClassNames } from 'src/utils/ReactUtils';

type ServicesProps = {
  servicesFamily: ServiceFamilyModel[];
  className?: string;
  testVersionName: string;
};
const Services: FunctionComponent<ServicesProps> = ({
  servicesFamily,
  className,
  testVersionName,
}) => {
  const { openQuotationEngine } = useQuotationEngineContext();
  const router = useRouter();

  const renderService = (service: ServiceFamilyModel) => (
    <div
      className={style.service}
      onClick={() => {
        if (!service.idFamilleIdg) {
          gtmHandleServiceClick('Voir toutes les prestations', testVersionName);
          router.push('/prestation');
          return;
        }
        gtmHandleServiceClick(service.title, testVersionName);

        openQuotationEngine({
          categoryId: service.idFamilleIdg,
        });
      }}
    >
      <div className={style.serviceIconWrapper}>
        <Icon
          name={service.icon as IconType}
          className={style.serviceIcon}
          width={30}
          height={30}
        />
      </div>
      <span className={style.serviceTitle}>{service.title}</span>
      <span className={style.rightArrow}>
        <Icon
          name="fleche-d"
          className={style.rightArrowIcon}
          height={16}
          width={16}
        />
      </span>
    </div>
  );

  return (
    <div className={mergeClassNames([style.services, className])}>
      {servicesFamily.map(renderService)}
      {renderService({
        icon: 'plus-moteur',
        title: 'Voir toutes les prestations',
      } as ServiceFamilyModel)}
    </div>
  );
};

export default Services;
