export const UNKNOWN_SERVICE = {
  categoryId: 50,
  subcategoryId: 5001,
  serviceId: '500101',
  name: 'rendez-vous',
};
export const SERVICE_TROUBLESHOOTING = '500102';
export const SERVICE_ASK_QUOTE = '500103';

export const TIRE_SERVICE = {
  categoryId: 22,
  subcategoryId: 2201,
  serviceId: '220101',
  name: 'pneu',
};

export const REGISTRATION_PLATE_MIN_LENGTH = 5;
