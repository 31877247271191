import React from 'react';
import type { FunctionComponent } from 'react';

import { useQuotationEngineContext } from 'src/components/layouts/PublicLayout/components/QuotationEngine/QuotationEngineContext';
import {
  ButtonPrimary,
  Icon,
  Image,
  InlineCritizr,
} from 'src/components/shared';

import { promiseEntries } from '../constants';
import PromiseText from '../PromiseText/PromiseText';
import Services from '../Services/Services';
import { gtmHandleSearchClick } from '../trackingUtils';
import style from './ServicesGrid.module.scss';

import type { ServiceFamilyModel } from 'src/models/service/serviceFamily.model';

import { mergeClassNames } from 'src/utils/ReactUtils';

type ServicesGridProps = {
  servicesFamily: ServiceFamilyModel[];
  averageRating: number;
  totalRatings: number;
  testVersionName: string;
  sectionClassName?: string;
};
const ServicesGrid: FunctionComponent<ServicesGridProps> = ({
  servicesFamily,
  averageRating,
  totalRatings,
  sectionClassName,
  testVersionName,
}) => {
  const { openQuotationEngine } = useQuotationEngineContext();

  return (
    <div className={style.container}>
      <div className={style.innerContainer}>
        <div className={style.promiseImage}>
          <Image
            src={promiseEntries.imageUrl}
            layout="fill"
            alt="devis réparation auto en ligne"
            priority
          />
        </div>
        <PromiseText />
        <div
          className={mergeClassNames([style.searchSection, sectionClassName])}
        >
          <div className={style.feedbackSection}>
            <h2 className={style.searchTitle}>
              Devis et rendez-vous facile et immédiat dans les garages ad
            </h2>
            <div className={style.searchFeedback}>
              <InlineCritizr
                averageRating={averageRating}
                totalRatings={totalRatings}
                className={style.searchCritizr}
              />
            </div>
          </div>

          <div
            className={style.searchEngine}
            onClick={() => {
              gtmHandleSearchClick(testVersionName);
              openQuotationEngine({});
            }}
          >
            <input
              type="text"
              name="ServiceInput"
              readOnly
              className={style.searchInput}
              placeholder={`Rechercher parmi nos 56 prestations`}
              aria-label="ad Services"
              aria-describedby="ad Services"
            />
            <ButtonPrimary
              className={style.searchButton}
              data-testid="home_engine_button"
            >
              <Icon name="recherche" height={22} width={22} />
              <span className={style.searchButtonLabel}>Rechercher</span>
              <Icon name="fleche-d" height={22} width={22} />
            </ButtonPrimary>
          </div>
        </div>
        <Services
          className={style.services}
          servicesFamily={servicesFamily}
          testVersionName={testVersionName}
        />
      </div>
    </div>
  );
};

export default ServicesGrid;
