import type { FunctionComponent } from 'react';
import React from 'react';
import TagManager from 'react-gtm-module';
import type { Advice } from 'src/models';

import { Image, Link } from 'src/components/shared';
import CMSRelatedLinks from 'src/components/shared/CMSLayout/components/CMSSidebar/components/CMSRelatedLinks';
import { Col, Container, Row } from 'src/components/ui';

import style from './ExpertAdvice.module.scss';

import { usePageType } from 'src/utils/ReactUtils';

const gtmClickEvent =
  (eventCategory: string, eventAction: string, eventLabel: string) => () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'eventGA',
        eventCategory,
        eventAction,
        eventLabel,
      },
    });
  };

export type ExpertAdviceProps = {
  advice: Advice[];
};

const ExpertAdvice: FunctionComponent<ExpertAdviceProps> = ({ advice }) => {
  const firstAdvice = advice[0];

  const pageType = usePageType();

  if (!firstAdvice?.description) {
    return null;
  }

  return (
    <>
      {advice.length > 0 && (
        <section className={style.expertAdvice}>
          <Container fluid="lg">
            <Row className={style.row}>
              <Col md={3} lg={3} className={style.imgCol}>
                <figure className={style.expertAdviceHeader}>
                  <div className={style.expertAdviceImage}>
                    <Image
                      layout="fill"
                      src="/images/components/ExpertAdvice/small-xavier-dark-grey.jpg"
                      alt="Nos conseils d'experts auto"
                    />
                  </div>
                  <div className={style.headerWrapper}>
                    <figcaption className={style.headerTitle}>
                      Nos conseils D’experts
                    </figcaption>
                  </div>
                </figure>
              </Col>
              <Col md={6} lg={6} className={style.contentContainer}>
                <div className={style.expertAdviceContent}>
                  <h3 className={style.contentTitle}>
                    Conseil de votre mécanicien auto
                  </h3>
                  <h3 className={style.contentSubtitle}>{firstAdvice.title}</h3>
                  <div className={style.contentText}>
                    <div
                      className={style.htmlContent}
                      dangerouslySetInnerHTML={{
                        __html: firstAdvice.description,
                      }}
                    />
                    <span className={style.opacity}></span>
                  </div>
                  <Link
                    onClick={gtmClickEvent(
                      pageType,
                      'clic voir plus',
                      firstAdvice.title,
                    )}
                    href={firstAdvice.url}
                  >
                    voir plus
                  </Link>
                </div>
              </Col>
              <Col md={3} lg={3} className={style.otherThemes}>
                <CMSRelatedLinks
                  title="Nos autres thèmes"
                  allLinksTitle="Voir tous les thèmes"
                  allLinksUrl="/guides/guide-conseil"
                  guidesLinks={advice.slice(1, 5)}
                ></CMSRelatedLinks>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default ExpertAdvice;
