import type { FunctionComponent } from 'react';
import React, { useState } from 'react';
import TagManager from 'react-gtm-module';
import type { AutoCompleteCityDetails, Vehicle } from 'src/models';
import VehicleVignette from 'src/pages/annonces-vehicules/components/vehicleVignette/VehicleVignette';

import { usePaginationFilters } from 'src/components/context/PaginationFiltersContext/utils';
import { ButtonTertiary, Link, Svg, Icon } from 'src/components/shared';
import Autocomplete from 'src/components/shared/Autocomplete/Autocomplete';
import { Col, Row } from 'src/components/ui';
import InputGroup from 'src/components/ui/InputGroup';

import style from './VehiclesAd.module.scss';

import { useAutoCompleteCitiesQuery } from 'src/services/cities/useAutocompleteCitiesQuery';

import { mergeClassNames, usePageType } from 'src/utils/ReactUtils';

export type VehiclesAdProps = {
  vehicles: Vehicle[];
};

const VehiclesAd: FunctionComponent<VehiclesAdProps> = ({ vehicles }) => {
  const pageType = usePageType();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCity, setSelectedCity] =
    useState<AutoCompleteCityDetails | null>(null);
  const { handleSetObjectFilter } = usePaginationFilters({ filters: {} });
  const { data: listAutoComplete } = useAutoCompleteCitiesQuery({
    params: { searchQuery },
  });
  const renderVignette = vehicles.map((vehicle, index) => (
    <Col key={index} xl={4} lg={6} md={12}>
      <VehicleVignette vehicle={vehicle} small />
    </Col>
  ));
  const gtmClickEvent = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'eventGA',
        eventCategory: 'module VO',
        eventAction: 'clic sur Voir tous nos vehicules',
        eventLabel: '/',
        pageType,
      },
    });
  };

  const onChoiceNear = () => {
    if (selectedCity) {
      handleSetObjectFilter({
        value: {
          near: `${selectedCity.locationLatitude}, ${selectedCity.locationLongitude}`,
          city: selectedCity.name,
          distance: '50km',
        },
        newPathname: '/annonces-vehicules',
      });

      TagManager.dataLayer({
        dataLayer: {
          event: 'eventGA',
          eventCategory: 'module VO',
          eventAction: 'clic rechercher moteur VO',
          eventLabel: '/',
          pageType,
          postalCode: selectedCity.zipCode,
        },
      });
    }
  };

  return (
    <div className={style.vehicles}>
      <Row className={`${style.box}`}>
        <Col xl={4} lg={3} md={6} className={style.column}>
          <div className={style.head}>
            <Svg
              src={`/icons/pages/annonces-vehicules/ad_occasion_certifiee.svg`}
              height={60}
              width={40}
            />
            <span className={style.headText}> NOUVEAU ! </span>
          </div>
          <h2 className={style.title}>
            <strong>VÉHICULES D’OCCASION CERTIFIÉS AD </strong> LIVRÉS DANS NOS
            GARAGES !
          </h2>
          <div className={style.text}>
            Profitez de notre expertise pour trouver le véhicule d’occasion qui
            vous correspond.
          </div>
          <div>
            <InputGroup className={style.searchInputWrapper}>
              <Autocomplete
                wrapperClassName={style.searchWrapper}
                className={style.searchInputText}
                placeholder={`Saisissez votre ville ou CP`}
                name="VehiclesServiceInput"
                id="VehiclesServiceInput"
                onClickItem={(value) => setSelectedCity(value)}
                items={listAutoComplete}
                onTyping={(value) => setSearchQuery(value)}
                labelDisplay={(value) => `${value.zipCode} - ${value.name}`}
                labelInputAfterSelect={(value) => value.name}
                dataTestId={'vehiclesServiceAutoComplete'}
              />
              <ButtonTertiary
                rightIcon={'fleche-d'}
                className={style.searchInputButton}
                onClick={onChoiceNear}
                dataTestId={'vehiclesServiceButton'}
              >
                Rechercher
              </ButtonTertiary>
            </InputGroup>
          </div>
        </Col>
        <Col xl={8} lg={9} md={6}>
          <div className={style.viewAll}>
            <Link href="/annonces-vehicules" title="occasion">
              <ButtonTertiary
                color={'Brown'}
                className={style.btnAll}
                rightIcon={'fleche-d'}
                onClick={gtmClickEvent}
              >
                Voir tous nos véhicules{' '}
              </ButtonTertiary>
            </Link>
          </div>
          <div className={style.vignetteWrapper}>{renderVignette}</div>
        </Col>
      </Row>

      <Row className={`d-sm-block d-md-none ${style.mobileWrapper}`}>
        <Col>
          <Link href="/annonces-vehicules" title="occasion">
            <button
              type={'button'}
              data-testid={'vehicles_btn'}
              className={style.btnMobile}
            >
              <Icon
                name={'ma-voiture'}
                width={50}
                height={50}
                className={mergeClassNames([
                  style.btnContent,
                  style.btnIconLeft,
                ])}
              />
              <span>ACHETER UN VÉHICULE D’OCCASION CERTIFIÉ AD</span>
              <Icon
                name={'fleche-d'}
                width={28}
                height={28}
                className={mergeClassNames([
                  style.btnContent,
                  style.btnIconRight,
                ])}
              />
            </button>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default VehiclesAd;
