import type { InferGetStaticPropsType } from 'next';
import type { FunctionComponent } from 'react';
import React from 'react';

import PublicLayout, {
  getPublicLayoutData,
} from 'src/components/layouts/PublicLayout/PublicLayout';
import AvisCritizr from 'src/components/shared/AvisCritizr/AvisCritizr';
import Contact from 'src/components/shared/Contact/Contact';
import Engagements from 'src/components/shared/Engagements/Engagements';
import MetaDescription from 'src/components/shared/Meta/MetaDescription';
import MetaRichSnippet from 'src/components/shared/Meta/MetaRichSnippet';
import MetaTitle from 'src/components/shared/Meta/MetaTitle';
import NetworkAd from 'src/components/shared/Network/NetworkAd';
import News from 'src/components/shared/News/News';
import Newsletter from 'src/components/shared/Newsletter/Newsletter';
import Partners from 'src/components/shared/Partenaires/Partners';
import PromiseComponent from 'src/components/shared/Promise/Promise';
import Services from 'src/components/shared/Services/Services';

import { getAdvice } from '../services/advice/getAdvice';
import Banners from './home/components/Banners/Banners';
import { promiseEntries } from './home/components/constants';
import ExpertAdvice from './home/components/ExpertAdvice/ExpertAdvice';
import PromiseAndSearch from './home/components/PromiseAndSearch/PromiseAndSearch';
import SearchEngineMobile from './home/components/PromiseAndSearch/SearchEngine/SearchEngineMobile';
import ServicesGrid from './home/components/ServicesGrid/ServicesGrid';
import StickyCta from './home/components/StickyCta/StickyCta';
import VehiclesAd from './home/components/VehiclesAd/VehiclesAd';
import style from './home/index.module.scss';
import seoRichSnippets from './home/richSnippet.json';

import { getBanners } from 'src/services/banners/getBanners';
import { getEngagements } from 'src/services/engagements/getNationalNews';
import { getFeedbacks, getFeedbackStats } from 'src/services/feedback';
import { getNetworkAd } from 'src/services/networkAd/getNetworkAd';
import { getNationalNews } from 'src/services/news/getNationalNews';
import { getPartners } from 'src/services/partners/getPartners';
import { getServices } from 'src/services/prestation/getServices';
import { getServiceFamilies } from 'src/services/services/getServiceFamilies';
import { getOccasionVehicles } from 'src/services/vehicle/getOccasionVehicles';

import { useFlag } from 'flags/client';

export const getStaticProps = async () => {
  const [
    [
      publicLayoutData,
      partners,
      newsItems,
      networkAd,
      engagements,
      adviceCollectionResponse,
    ],
    [
      serviceFamilies,
      carServiceBlocks,
      vehicles,
      banners,
      feedbacks,
      feedbackStats,
    ],
  ] = await Promise.all([
    Promise.all([
      getPublicLayoutData(),
      getPartners(300),
      getNationalNews(),
      getNetworkAd(),
      getEngagements(),
      getAdvice({
        orderBy: 'updatedAt',
        order: 'desc',
        itemsPerPage: 5,
      }),
    ]),
    Promise.all([
      getServiceFamilies({ itemsPerPage: 5 }),
      getServices({ hasCmsBlock: true }),
      getOccasionVehicles(),
      getBanners(),
      getFeedbacks(),
      getFeedbackStats(),
    ]),
  ]);
  const { data: advice } = adviceCollectionResponse;

  return {
    props: {
      publicLayoutData,
      partners,
      newsItems,
      networkAd,
      engagements,
      advice,
      serviceFamilies,
      carServiceBlocks,
      vehicles,
      banners,
      feedbacks,
      feedbackStats,
    },
  };
};

const HomePage: FunctionComponent<
  InferGetStaticPropsType<typeof getStaticProps>
> = ({
  publicLayoutData,
  partners,
  newsItems,
  networkAd,
  engagements,
  advice,
  serviceFamilies,
  carServiceBlocks,
  vehicles,
  banners,
  feedbacks,
  feedbackStats,
}) => {
  const [flag] = useFlag('Bloc-moteur-home-V2');

  return (
    <>
      <MetaTitle title="Entretien Voiture - Devis Réparation Automobile Gratuit | Garages AD" />
      <MetaDescription description="Faites confiance aux experts AD pour l'entretien et la réparation de votre voiture. ✓ Tout type de marque. ✓ Jusqu'à 50% de remise. ▶ Prenez RDV en Ligne !" />
      {seoRichSnippets.map((scriptContent, index) => (
        <MetaRichSnippet key={index} content={scriptContent} />
      ))}

      <PublicLayout data={publicLayoutData}>
        <div className={style.mainHeaderMobileWrapper}>
          <div className={style.mainHeader}>
            {flag === 'Version initiale moteur' ? (
              <PromiseAndSearch
                servicesFamily={serviceFamilies}
                averageRating={
                  publicLayoutData.footerData.feedbackStats.reviewNote
                }
                totalRatings={
                  publicLayoutData.footerData.feedbackStats.countReview
                }
              />
            ) : null}

            {flag === 'Version mosaïque moteur bordeaux' ? (
              <ServicesGrid
                testVersionName={flag}
                servicesFamily={serviceFamilies}
                averageRating={
                  publicLayoutData.footerData.feedbackStats.reviewNote
                }
                totalRatings={
                  publicLayoutData.footerData.feedbackStats.countReview
                }
              />
            ) : null}

            {flag === 'Version mosaïque moteur noir' ? (
              <ServicesGrid
                testVersionName={flag}
                servicesFamily={serviceFamilies}
                averageRating={
                  publicLayoutData.footerData.feedbackStats.reviewNote
                }
                totalRatings={
                  publicLayoutData.footerData.feedbackStats.countReview
                }
                sectionClassName={style.blackSection}
              />
            ) : null}
            <Banners banners={banners} />
          </div>
          <div>
            <div className={style.mainMobileSearch}>
              <div className={style.mainPromise}>
                <PromiseComponent
                  imageUrl={promiseEntries.imageUrl}
                  title={promiseEntries.title}
                  text1={promiseEntries.text1}
                  text2={promiseEntries.text2}
                  detail={promiseEntries.detail}
                />
              </div>
              <SearchEngineMobile
                averageRating={
                  publicLayoutData.footerData.feedbackStats.reviewNote
                }
                totalRatings={
                  publicLayoutData.footerData.feedbackStats.countReview
                }
              />
            </div>
            <VehiclesAd vehicles={vehicles} />
          </div>
        </div>

        <Services
          carServiceBlocks={carServiceBlocks}
          title="CHOISISSEZ VOTRE RÉPARATION MOINS CHÈRE AVEC UN GARAGE AUTO AD"
        />
        <News
          newsItems={newsItems}
          title={
            "PROFITEZ DE NOS OFFRES ET PROMOTIONS SUR L'ENTRETIEN DE VOTRE VOITURE  "
          }
        />
        <Engagements
          url="nos-engagements"
          maxLengthDesc={55}
          engagements={engagements}
          title={'NOS ENGAGEMENTS POUR VOTRE VOITURE'}
          titleLevel={'h2'}
          itemTitleLevel={'h3'}
        />
        <AvisCritizr
          feedbacks={feedbacks}
          averageRating={feedbackStats.reviewNote}
          totalRatings={feedbackStats.countReview}
          title={'NOS CLIENTS RECOMMANDENT LES GARAGES ET CARROSSERIES AD'}
          titleLevel={'h2'}
        />
        <ExpertAdvice advice={advice} />
        <Partners redirectUrl="/partenaires" partners={partners} />
        <Newsletter />
        <NetworkAd networkItems={networkAd} />
        <Contact linkPath={'/contact'} label={'Nous contacter'} />
        <StickyCta />
      </PublicLayout>
    </>
  );
};

export default HomePage;
