import type { FunctionComponent } from 'react';
import React, { useState, useCallback, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import type { BannerModel } from 'src/models';
import Carousel from 'src/packages/Carousel';

import { ButtonPrimary, Image, Link } from 'src/components/shared';

import style from './Banners.module.scss';

import { usePageType } from 'src/utils/ReactUtils';

export type BannersProps = {
  banners: BannerModel[];
};

const gtmClick = (
  pageType: string,
  index: number,
  event: string,
  title: string,
) => {
  TagManager.dataLayer({
    dataLayer: {
      event,
      promoNameArr: title,
      promoPositionArr: index,
      page: pageType,
    },
  });
};

const Banners: FunctionComponent<BannersProps> = ({ banners }) => {
  const bannersToDisplay = banners
    ?.slice()
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .slice(0, 5);

  const pageType = usePageType();
  const [bannerTracked, setBannerTracked] = useState<number[]>([]);

  const trackingView = useCallback(
    (index: number) => {
      if (!bannerTracked.includes(index)) {
        setBannerTracked([...bannerTracked, index]);
        gtmClick(pageType, index, 'promoView', bannersToDisplay[index]?.title);
      }
    },
    [bannerTracked, bannersToDisplay, pageType],
  );

  useEffect(() => {
    if (bannersToDisplay?.length) {
      trackingView(0);
    }
  }, [pageType, bannersToDisplay, bannersToDisplay?.length, trackingView]);

  const bannerSliderPhotos = bannersToDisplay.map((banner, index) => (
    <div className={style.bannerImageContainer} key={index}>
      <Image
        width={915}
        height={528}
        key={index}
        src={banner.icon}
        alt={banner.alt ?? banner.title}
      />
      <Link
        data-testid={`banner_link_${index}`}
        onClick={() => {
          gtmClick(
            pageType,
            index + 1,
            'promoClick',
            bannersToDisplay[index].title,
          );
        }}
        href={banner.url}
        className={style.bannersLink}
      >
        lien vers la promotion {banner.alt}
      </Link>

      <ButtonPrimary
        url={banner.url}
        className={style.bannersBtn}
        handleOnClick={() => {
          gtmClick(
            pageType,
            index + 1,
            'promoClick',
            bannersToDisplay[index].title,
          );
        }}
        rightIcon="fleche-d"
      >
        <span>{"J'en profite"}</span>
      </ButtonPrimary>
    </div>
  ));

  const bannerSliderThumbnails = () =>
    bannersToDisplay.map((banner, index) => (
      <h3
        key={index}
        data-testid={`pagination_dot_${index}`}
        className={`${style.bannersNavLink}`}
      >
        {banner?.title}
      </h3>
    ));

  return (
    <div className={style.bannersContainer}>
      <div className={style.banners}>
        <Carousel
          className={style.bannersCarousel}
          animationDuration={800}
          autoPlay
          autoPlayInterval={3000}
          hasArrows={false}
          onSlideChanged={(index) => trackingView(index)}
          breakpoints={{
            mediumDesktop: {
              customPagination: bannerSliderThumbnails(),
            },
          }}
          customPagination={undefined}
        >
          {bannerSliderPhotos}
        </Carousel>
      </div>
    </div>
  );
};

export default Banners;
